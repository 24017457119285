<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getDealSteps">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" @click.stop="addDealStep()" color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="8" class="title_menu mt-1">
                                        {{  $t('search_deal_step') }}{{ process ? (': ' + process.name) : '' }}
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4"  class="text-right">
                                        <v-btn :to="{name: 'deal_step.create'}" class="infinity_button" color="primary">
                                            {{$t('create') }}
                                        </v-btn>
                                        <v-btn v-if="deal_stepItems.length === 0"  @click="dialogCopy=true" class="infinity_button ml-4" color="primary">
                                            {{$t('copy') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">

                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="deal_step" rules=""
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="business_process"
                                                      :items="businessProcessItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('business_process')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="filter_show" class="px-4 py-4">
                                <v-row>
                                    <v-col cols="12"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                               color="primary">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table
                            item-key="id" ref="table" :headers="headers" :items="deal_stepItems"
                            :options.sync="options" disable-pagination
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                            :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                            hide-default-footer
                            class="sortable-table elevation-0"
                        >

                            <template v-slot:item.sort>
                                <v-btn icon class="handle">
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <span  class="cursor-pointer font_weight_600" @click="editDealStep(item)" >
                                        {{  item.name }}
                                        {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                    </span>
                                    <v-icon v-if="!item.deleted" @click="deleteDealStep(item)" small>mdi-delete-outline</v-icon>
                                </div>
                            </template>
                            <template v-slot:item.business_process="{ item }">
                                <div>
                                    <v-chip :color="item.color" small light dark></v-chip>
                                    {{item.business_process}}
                                </div>
                            </template>

                            <template v-slot:item.departments="{ item }">
                                {{ item.departments.map(id => { return departments.find(d => d.id === id)?.name }).join('/') }}
                            </template>

                            <template v-slot:item.responsibles="{ item }">
                                <div v-if="item.responsibles && item.responsibles.length === 1 && item.responsibles[0] && item.responsibles[0].name">
                                    <v-list subheader two-line class="background_none py-0">
                                        <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                            <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                                <img v-if="item.responsibles[0].photo !== '/img/avatar.png'" :src="item.responsibles[0].photo" :alt="item.responsibles[0].name">
                                                <v-icon v-else color="primary" size="22">
                                                    ${{'settingsIcon'}}
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0 title_subtitle">
                                                <v-list-item-title v-text="item.responsibles[0].name" ></v-list-item-title>
                                                <v-list-item-subtitle v-text="formatPhoneNumber(item.responsibles[0].phone)"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>

                                <div v-if="item.responsibles && item.responsibles.length > 1">
                                    <div class="avatars cursor-pointer">
                                        <span class="avatar" v-for="(item, index) in item.responsibles"  :key="index">
                                              <v-avatar size="36" >
                                                  <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                                                  <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                                              </v-avatar>
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogCopy"
        >

            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('copy_process') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="dialogCopy=false" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-5">
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="business_process" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="business_process"
                                              :items="businessProcessItems" :error-messages="errors"
                                              :error="!valid"
                                              item-text="name" item-value="id"
                                              :label="$t('business_process')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 pt-10 pb-7">
                        <v-btn @click="copyProcess()" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                            {{ $t('copy') }}
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            class="ma-1 button_cancel"
                            plain
                            @click="dialogCopy=false"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>

                    </v-card-actions>

                </v-card>
            </ValidationObserver>

        </v-dialog>

    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Sortable from 'sortablejs'
import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";

export default {
    name: 'DealStep',
    components: {
        ValidationProvider,
        ValidationObserver,
        SettingMenu
    },
    props: {
        businessProcessId: {
            default: null
        }
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: this.$t('deal_step_creation'),
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            deal_stepItems: [],
            errors: {},
            id: null,
            name: null,
            deal_step: null,
            departments: [],
            headers: [
                {
                    text: "",
                    align: "center",
                    sortable: false,
                    value: "sort",
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('buttons'),
                    align: "left",
                    sortable: false,
                    value: "button"
                },
                {
                    text: this.$t('deal_status'),
                    align: "left",
                    sortable: false,
                    value: "deal_status"
                },
                {
                    text: this.$t('departments'),
                    align: "left",
                    sortable: false,
                    value: "departments"
                },
                {
                    text: this.$t('responsibles'),
                    align: "left",
                    sortable: false,
                    value: "responsibles"
                },
                // {
                //     text: this.$t('business_process'),
                //     align: "left",
                //     sortable: false,
                //     value: "business_process",
                // },
            ],
            filter_show: false,
            business_process: null,
            businessProcessItems: [],
            dialogCopy: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang','BusinessProcessId']),
        languages() {
            return this.listLanguages
        },

        process() {
            return this.businessProcessItems.find(item => item.id === parseInt(this.businessProcessId))
        }
    },

    async mounted() {
        this.language = this.languages[this.tab]
        this.business_process = this.businessProcessId ?? this.$route.params.business_process ?? null
        await this.checkCreate()
        await this.getBusinessProcesses();
        let table = this.$refs.table.$el.querySelector("tbody")
        Sortable.create(table, {
            handle: ".handle",
            onEnd: this.dragReorder
        })

        this.fetchDepartments()

    },

    watch: {
        options: {
            handler() {
                this.getDealSteps()
            },
            deep: false
        }
    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5Sort' : 'rowMobiClass';
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        checkCreate() {
            this.heading = this.$t('deal_step_creation')

        },
        dragReorder({newIndex, oldIndex}) {
            const rowSelected = this.deal_stepItems.splice(oldIndex, 1)[0]
            this.deal_stepItems.splice(newIndex, 0, rowSelected)
            this.saveSortDealStep()
        },
        async saveSortDealStep() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.deal_stepItems && this.deal_stepItems.length > 0) {
                for (let i in this.deal_stepItems) {
                    if (this.deal_stepItems[i].id !== 'undefined' && this.deal_stepItems[i].id > 0) {
                        formData.append(`sort[${i}]`, this.deal_stepItems[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/deal_step/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_step_sorting_updated'))
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_step_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getDealSteps() {
            this.loading = true
            const {
                sortBy,
                sortDesc,
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'desc' : 'asc'
            }
            if (this.deal_step) {
                params.name = this.deal_step
            }
            if (this.business_process) {
                params.business_process = this.business_process
            }
            else if(this.BusinessProcessId){
                params.business_process = this.BusinessProcessId;
            }

            await this.$http
                .get("admin/deal_step", {
                    params: params,
                })
                .then(res => {
                    this.deal_stepItems = res.body.data
                })
                .catch(err => {
                    this.deal_stepItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteDealStep(item) {
            if (confirm(this.$t('delete_deal_step'))) {
                this.loading = true
                await this.$http
                    .delete(`admin/deal_step/${item.id}`)
                    .then(res => {
                        this.getDealSteps()
                    })
                    .catch(err => {
                        this.DealStepStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        addDealStep() {
            this.$router.push({
                name: 'deal_step.create',
            })
        },
        editDealStep(item) {
            this.$router.push({
                name: 'deal_step.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getBusinessProcesses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.businessProcessItems = res.body.data
                })
                .catch(err => {
                    this.businessProcessItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async copyProcess(){
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('business_process_copy', this.business_process)
            formData.append('business_process', this.$route.params.business_process)

            await this.$http
                .post(`admin/deal_step/copy`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_step_copy'))
                    this.business_process = null;
                    this.dialogCopy = null;
                    this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_step_not_copy'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async fetchDepartments() {
            this.loading = true
            await this.$http
                .get("admin/department")
                .then(res => {
                    this.departments = res.body.data
                })
                .catch(err => {
                    this.departments = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
